<div class="content-wrapper container-xxxl p-0 card">
  <div class="content-body card-body" id="ngx-datatable-row-details">
    <div class="row mb-1 justify-content-end align-items-center">
      <div class="col-auto pl-0" *ngIf="tag_resultConnectSf4c_exit">
        <button *ngIf="loading;else noLoading" class="btn btn-primary btn-icon place-order" disabled>
          <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
          <span [translate]="'TALENT_POOL.MANAGE_JOB.SYNC_SUCCESSFACTORS'"></span>
        </button>
        <ng-template #noLoading>
          <button class="btn btn-primary btn-icon place-order" rippleEffect (click)="sync_sf4c_jr()">
            <span [translate]="'TALENT_POOL.MANAGE_JOB.SYNC_SUCCESSFACTORS'"></span>
          </button>
        </ng-template>

      </div>
      <div class="col-auto pl-0">
        <a routerLink="/manage-job/create-job" class="btn btn-primary"
          [translate]="'TALENT_POOL.MANAGE_JOB.ADD_JOB'">

        </a>
      </div>
      <!-- <div class="col-2 pl-0">
        <input type="text" class="form-control" id="job-search"
          [placeholder]="'TALENT_POOL.MANAGE_JOB.PLACEHOLDER'|translate" [(ngModel)]="searchText" (input)="Search()" />
      </div> -->
      <div class="col-3 pl-0" *ngIf="user.role=='Site Admin' ||user.role=='Super admin' || user.role=='Admin' || user.is_superuser">
        <ng-select [multiple]="true" [items]="owner__email_items" bindLabel="name" bindValue="value"
        [placeholder]="'TALENT_POOL.MANAGE_JOB.CHOICE_OWNER'|translate" name="owner__email"
        [(ngModel)]="owner__email" id="owner__email" (ngModelChange)="filterOwner()">

      </ng-select>
      </div>
    </div>
    <ngx-datatable id="table-jd" #tableJd [rows]="list_all_jd" [rowHeight]="50" [externalPaging]="true"
      class="bootstrap core-bootstrap" [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50" [count]="page.totalElements" [offset]="page.pageNumber" [limit]="page.size"
      (page)="setPage($event)" [scrollbarH]="true">
      <ngx-datatable-column [width]="250" prop="job_title" [name]="'TALENT_POOL.MANAGE_JOB.JOB'|translate">
        
        <ng-template ngx-datatable-cell-template let-row="row">
          <div class="w-100">
            <a routerLink="/manage-job/detail-job/{{row.id}}"
              class="d-inline-block text-truncate max-width-custom w-100" title="{{row.job_title}}">{{ row.job_title }}</a>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <!-- <ngx-datatable-column [name]="'TALENT_POOL.CREATE_JOB.INDUSTRY'|translate" [width]="widthColumn">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="w-100">
                  <ul class="list-unstyled">
                    <li *ngFor="let _row of row.industry">{{ _row }}</li>
                  </ul>
              </div>
            </ng-template>
        </ngx-datatable-column> -->
      <!-- <ngx-datatable-column [name]="'TALENT_POOL.CREATE_JOB.JOB_TYPE'|translate" prop="job_type" width="150">
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'TALENT_POOL.CREATE_JOB.JOB_LEVEL'|translate" prop="job_level" width="150">
        </ngx-datatable-column> -->
      <ngx-datatable-column [width]="125">
        <ng-template ngx-datatable-header-template>
          <div class="header-template" *ngIf="language=='en';else VN">
            <span>Recommended</span><br>
            <span>candidates</span>
          </div>
          <ng-template #VN>
            <div class="header-template">
              <span>Gợi ý</span><br>
              <span>hồ sơ</span>
            </div>
          </ng-template>
          
        </ng-template>
        <ng-template ngx-datatable-cell-template let-row="row">
          <div class="w-100">
            <a href="javascript:void(0);" (click)="modalOpenFilterRecommend(modalBasicFilterRecommend)"
              [translate]="'TALENT_POOL.MANAGE_JOB.RECOMMEND'"></a>
          </div>
          <ng-template #modalBasicFilterRecommend let-modal>
            <form>
              <div class="modal-header">
                <h4 class="modal-title">
                  <span [translate]="'TALENT_POOL.RECOMMEND_RESUME.SEARCH_REQUIRED'"></span>
                  <span class="font-weight-bolder"> {{row.job_title}}</span>

                </h4>
              </div>
              <div class="modal-body" ngbAutofocus style="overflow-y: visible;">
                <div class="form-group">
                  <div class="col-form-label">
                    <label for="job_title"><b [translate]="'TALENT_POOL.RECOMMEND_RESUME.TIME'"></b></label>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-6">
                      <ng2-flatpickr [placeholder]="'TALENT_POOL.RECOMMEND_RESUME.FROM'|translate" [config]="dateTimeOptions" [(ngModel)]="from" name="from" id="from"></ng2-flatpickr>
                      <!-- <input [(ngModel)]="from" bsDatepicker type="text" class="form-control" id="from" name="from"
                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }" autocomplete="off"
                        [placeholder]="'TALENT_POOL.RECOMMEND_RESUME.FROM'|translate" /> -->
                    </div>

                    <div class="col-6">
                      <ng2-flatpickr [placeholder]="'TALENT_POOL.RECOMMEND_RESUME.TO'|translate" [config]="dateTimeOptions" [(ngModel)]="to" name="to" id="to"></ng2-flatpickr>

                      <!-- <input [(ngModel)]="to" bsDatepicker type="text" class="form-control" id="to" name="to"
                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }" autocomplete="off"
                        [placeholder]="'TALENT_POOL.RECOMMEND_RESUME.TO'|translate" /> -->
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-6">
                      <div class=" col-form-label">
                        <label for="selectedCity"><b [translate]="'TALENT_POOL.SEARCH_RESUME.ADDRESS'"></b></label>
                      </div>
                      <div class="row">
                        <div class="col-md-12 col-12">
                          <ng-select [items]="city" bindLabel="name" bindValue="value"
                            [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER_ADDRESS'|translate"
                            [(ngModel)]="selectedCity" [multiple]="true" name="selectedCity">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-6">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" [(ngModel)]="company_talentpool"
                            id="company_talentpool" name="company_talentpool" />
                        <label class="custom-control-label" for="company_talentpool"
                            [translate]="'TALENT_POOL.RECOMMEND_RESUME.COMPANY_TALENTPOOL'">
                        </label>
                        </div>
                    </div> -->
                    <div class="col-6">
                      <div class="form-group">
                        <label for="selectedType"><b [translate]="'TALENT_POOL.RECOMMEND_RESUME.RECOMMENDATION_FILE'">Kho du lieu</b></label>
                        
                        <div *ngFor="let type of typeTalentPoolItems" class="ml-2">
                          <input type="radio" name="selectedType" [id]="type.name" class="form-check-input" [(ngModel)]="selectedType"
                            [value]="type.id" (change)="changeTypeEvent()"/>
  
                          <label for="selectedType" *ngIf="language=='vn';else EN">{{type.name}}</label>
                          <ng-template #EN>
                          <label for="selectedType">{{type.name_en}}</label>
                          </ng-template>
                        </div>
                        <ng-select *ngIf="selectedType==3" [placeholder]="'TALENT_POOL.RECOMMEND_RESUME.CHOOSE_TALENT_POOL'|translate" [searchable]="false" [items]="talentPools" bindLabel="name" bindValue="id"
                        [(ngModel)]="talent_pool" name="talent_pool">
                      </ng-select>
                      
  
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div class="modal-footer">
                <button [translate]="'TALENT_POOL.RECOMMEND_RESUME.RECOMMEND'" type="button"
                  (click)="filterRecommend(row)" (click)="modal.close('Accept click')"
                  class="btn btn-primary">Recommend</button>
                <button (click)="modal.close('Accept click')" rippleEffect class="btn btn-secondary ml-1"
                  [translate]="'TALENT_POOL.MANAGE_JOB.MODAL.CANCEL'">Cancel</button>
                
              </div>
            </form>
          </ng-template>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="125">
        <ng-template ngx-datatable-header-template>
          <div class="header-template" *ngIf="language=='en';else VN">
            <span>Saved</span><br>
            <span>candidates</span>
          </div>
          <ng-template #VN>
            <div class="header-template">
              <span>Hồ sơ</span><br>
              <span>đã lưu</span>
            </div>
          </ng-template>
        </ng-template>
        <ng-template ngx-datatable-cell-template let-row="row">
          <div class="w-100">
            <div>
              <a routerLink="/manage-job/saved-cvs/{{row.id}}"
              >
              {{row.num_saved_candidates}}
              <span class="text-warning" *ngIf="row.num_new__candidates!=0">({{
                row.num_new__candidates
                }} <span [translate]="'TALENT_POOL.RESUME_TALENT_POOL.NEW'"></span>)</span>
              </a>     
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="125" *ngIf="tag_resultConnectSf4c_exit">
        <ng-template ngx-datatable-header-template>
          <div class="header-template">
            <div class="header-template" *ngIf="language=='en';else VN">
              <span>Recruitment</span><br>
              <span>candidates</span>
            </div>
            <ng-template #VN>
              <div class="header-template">
                <span>Hồ sơ</span><br>
                <span>ứng tuyển</span>
              </div>
            </ng-template>   
          </div>
        </ng-template>
        <ng-template ngx-datatable-cell-template let-row="row">
          <div class="w-100" *ngIf="row.hcm_system_jd">
            <div>
              <a routerLink="/manage-job/detail-job/{{row.id}}/tab-candidates"
                >
                {{row.num_sf4c_candidates}}
                <!-- <span class="text-warning" *ngIf="row.num_new_sf4c_candidate!=0">({{
                  row.num_new_sf4c_candidate
                  }} <span [translate]="'TALENT_POOL.RESUME_TALENT_POOL.NEW'"></span>)</span> -->
              </a>
            </div>
              
          </div>
        </ng-template>
      </ngx-datatable-column>
      
      <!-- <ngx-datatable-column [name]="'TALENT_POOL.MANAGE_JOB.NUM_SAVED_RESUMES'|translate" prop="num_talent_pool_saved_cvs" width="150">
        </ngx-datatable-column> -->
        <ngx-datatable-column prop="id"
        [width]="138">
        <ng-template ngx-datatable-header-template>
          <div class="header-template">
            <div class="header-template" *ngIf="language=='en';else VN">
              <span>ID job </span><br>
              <span>CHR</span>
            </div>
            <ng-template #VN>
              <div class="header-template">
                <span>ID công việc</span><br>
                <span>CHR</span>
              </div>
            </ng-template>   
          </div>
        </ng-template>
        <ng-template ngx-datatable-cell-template let-row="row">
          <div class="w-100">
            <div>
              {{row.id}}
            </div>
              
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="tag_resultConnectSf4c_exit" prop="hcm_system_jd"
        [width]="125" [name]="'TALENT_POOL.MANAGE_JOB.HCM_SYSTEM_ID'|translate">
        
      </ngx-datatable-column>
      <ngx-datatable-column prop="owner" [width]="200" [name]="'TALENT_POOL.MANAGE_JOB.OWNER'|translate">
        
      </ngx-datatable-column>
      <ngx-datatable-column [width]="125" [name]="'TALENT_POOL.MANAGE_JOB.ACTION'|translate">

        <ng-template ngx-datatable-cell-template let-row="row">
          <div class="d-flex align-items-center">
            <a type="button" href="javascript:void(0)" routerLink="/manage-job/edit-job/{{row.id}}" title="Sửa"
              class="btn btn-icon btn-flat-primary rounded-circle" rippleEffect>
              <span class="d-flex align-items-center justify-content-center"><i class="fa fa-pencil-square-o"></i>
              </span>
            </a>
            <a type="button" href="javascript:void(0)" (click)="deleteJob(row.id)" title="Xóa"
              class="btn btn-icon btn-flat-danger rounded-circle" rippleEffect>
              <span class="d-flex align-items-center justify-content-center"><i class="fa fa-trash-o"></i>
              </span>
            </a>
          </div>

        </ng-template>
      </ngx-datatable-column>

    </ngx-datatable>

  </div>
</div>